import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _109ab394 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _4f418e90 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _e1dc7390 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _3c6fd0ec = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _21e02196 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _372058ce = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _3486c6bb = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _4c0ebc32 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _d3a19c04 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _223ddbd4 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _1aff8f32 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _cb1860a6 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _e6cb482a = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _4d04aaf1 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _5e0d0187 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _78bd3d6a = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _73671170 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _4429230d = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _6e4c430f = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _4d8e47f6 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _9d788ee4 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _109ab394,
    name: "kits"
  }, {
    path: "/lojas",
    component: _4f418e90,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _e1dc7390,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _3c6fd0ec,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _21e02196,
    name: "motos"
  }, {
    path: "/reset",
    component: _372058ce,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _3486c6bb,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _4c0ebc32,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _d3a19c04,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _223ddbd4,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _1aff8f32,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _cb1860a6,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _e6cb482a,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _4d04aaf1,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _5e0d0187,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _78bd3d6a,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _73671170,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _4429230d,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _6e4c430f,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _4d8e47f6,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _9d788ee4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
